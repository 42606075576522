import { Tldraw } from "@tldraw/tldraw";
import './index.css';
import { Box, Typography } from "@mui/joy";
import Lottie from "lottie-react";
import logo from '../src/assets/picaso.json';

function App() {
  return (
    <Box>
      <Box sx={{ padding: 1.8, background: 'linear-gradient(to right, #474787, #2c2c54)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Lottie animationData={logo} loop={false} style={{ width: 30, height: 30 }} />
          <Box sx={{ paddingX: 2, maxWidth: 320}}>
              <Typography level="title-sm" noWrap sx={{ color: "white" }}>Untitled Design</Typography>
          </Box>
      </Box>
      <Box style={{ position: "fixed", inset: '58.78px 0 0 0' }}>
        <Tldraw persistenceKey="demo" />
      </Box>
    </Box>
  );
}

export default App;
